<template>
  <div>
    <setting-aside
      v-if="aside === 'setting'"
      :active="true"
      :merchant="merchant"
      :setting-keys="availableSettingKeys"
      :resource="settingData"
      @changed="refresh"
      @aside="bol => { closeAside(bol); !bol && refresh() } "
    />

    <v-card class="mb-2">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="mb-4 me-3"
          :disabled="availableSettingKeys.length == 0"
          @click="toggleAside(null, 'settings')"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Add New Setting</span>
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="tableColumnHeaders"
        :items="settings"
        :sort-by="['id']"
        :sort-desc="[true]"
      >
        <!-- id -->
        <template #[`item.id`]="{item}">
          <div class="d-flex align-center">
            {{ item.id }}
          </div>
        </template>

        <!-- category -->
        <template #[`item.category`]="{item}">
          <div class="d-flex align-center">
            {{ item.category }}
          </div>
        </template>

        <!-- key -->
        <template #[`item.key`]="{item}">
          <div class="d-flex align-center">
            {{ item.key }}
          </div>
        </template>

        <!-- value -->
        <template #[`item.value`]="{item}">
          <div
            v-if="item.value_type === 'array'"
            class="d-flex align-center"
          >
            <div v-if="item.value.length > 0">
              <v-chip
                v-for="(value, index) in item.value"
                :key="index"
                class="mr-1"
              >
                {{ value }}
              </v-chip>
            </div>
            <div v-else>
              NIL
            </div>
          </div>
          <div
            v-else
            class="d-flex align-center"
          >
            {{ item.value }}
          </div>
        </template>

        <!-- Action -->
        <template #[`item.action`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                @click="toggleAside(item, 'setting')"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { deleteSetting } from '@/api/merchant/setting'
import { useConfirm, useNotifyErrors } from '@/composables'
import { t } from '@/plugins/i18n'
import {
mdiDeleteOutline, mdiDotsVertical, mdiPencil, mdiPencilOutline, mdiPlus,
} from '@mdi/js'
import { computed, onMounted, ref } from '@vue/composition-api'
import SettingAside from '../../setting-resource/SettingAside.vue'
import useMerchantSetting from '../../useMerchantSetting'

export default {
  name: 'MerchantSettingList',

  components: { SettingAside },

  props: {
    merchant: {
      type: Object,
      required: true,
    },
    settings: {
      type: Array,
      required: true,
    },
  },

  setup(props, { emit }) {
    const aside = ref('')
    const ready = ref(false)
    const settingData = ref(null)
    const keysUsed = ref([])

    const { settingKeys } = useMerchantSetting()

    const getKeysUsed = () => {
      keysUsed.value = props.settings.map(setting => setting.key)
    }

    const availableSettingKeys = computed(() => settingKeys.filter(setting => !keysUsed.value.includes(setting.key)))

    const tableColumnHeaders = computed(() => [
      { text: 'ID', value: 'id', sortable: true },
      { text: 'CATEGORY', value: 'category', sortable: false },
      { text: 'KEY', value: 'key', sortable: false },
      { text: 'VALUE TYPE', value: 'value_type', sortable: false },
      { text: 'VALUE', value: 'value', sortable: false },
      { text: 'ACTION', value: 'action', sortable: false },
    ])

    const destroy = async setting => {
      await useConfirm({
        title: 'Delete notification setting',
        content: 'Are you sure?',
      })

      try {
        await deleteSetting(props.merchant.id, setting.id)
        refresh()
      } catch (error) {
        useNotifyErrors(error)
      }
    }

    const closeAside = bol => {
      if (!bol) {
        settingData.value = null
        aside.value = null
      }
    }

    const toggleAside = slot => {
      settingData.value = slot
      aside.value = 'setting'
    }

    const refresh = async () => {
      ready.value = false

      emit('updated')
      closeAside()

      ready.value = true
    }

    onMounted(() => {
      getKeysUsed()
    })

    return {
      t,
      aside,
      ready,
      settingData,
      availableSettingKeys,
      tableColumnHeaders,

      refresh,
      closeAside,
      toggleAside,

      destroy,

      icons: {
        mdiPencil,
        mdiPlus,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style scoped>
.cross-out {
  text-decoration: line-through;
  opacity: 0.5;
}
</style>
