var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.aside === 'setting')?_c('setting-aside',{attrs:{"active":true,"merchant":_vm.merchant,"setting-keys":_vm.availableSettingKeys,"resource":_vm.settingData},on:{"changed":_vm.refresh,"aside":function (bol) { _vm.closeAside(bol); !bol && _vm.refresh() }}}):_vm._e(),_c('v-card',{staticClass:"mb-2"},[_c('v-card-title',[_c('v-spacer'),_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary","disabled":_vm.availableSettingKeys.length == 0},on:{"click":function($event){return _vm.toggleAside(null, 'settings')}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Add New Setting")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumnHeaders,"items":_vm.settings,"sort-by":['id'],"sort-desc":[true]},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.category)+" ")])]}},{key:"item.key",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.key)+" ")])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [(item.value_type === 'array')?_c('div',{staticClass:"d-flex align-center"},[(item.value.length > 0)?_c('div',_vm._l((item.value),function(value,index){return _c('v-chip',{key:index,staticClass:"mr-1"},[_vm._v(" "+_vm._s(value)+" ")])}),1):_c('div',[_vm._v(" NIL ")])]):_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(item.value)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.toggleAside(item, 'setting')}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('span',[_vm._v("Edit")])],1)],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }