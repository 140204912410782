export default function useMerchantSetting() {
  const categories = ['notification', 'report']

  const valueTypes = ['string', 'integer', 'array', 'boolean', 'datetime']

  const settingKeys = [
    {
      category: 'notification',
      key: 'locale',
      value_type: 'string',
      description: 'Language',
    },
    {
      category: 'notification',
      key: 'new_order_recipients',
      value_type: 'array',
      description: 'New order notification recipients',
    },
    {
      category: 'notification',
      key: 'cancel_order_recipients',
      value_type: 'array',
      description: 'Cancel order notification recipients',
    },
    {
      category: 'notification',
      key: 'topup_order_recipients',
      value_type: 'array',
      description: 'Topup reservation or applications notification recipients',
    },
    {
      category: 'notification',
      key: 'low_stock_recipients',
      value_type: 'array',
      description: 'Low Stock alert recipients',
    },
    {
      category: 'notification',
      key: 'low_stock_days',
      value_type: 'integer',
      description: 'receive low stock alert per N days',
    },
    {
      category: 'notification',
      key: 'out_of_stock_recipients',
      value_type: 'array',
      description: 'Out of Stock alert recipients',
    },

    // {
    //   category: 'report',
    //   key: 'sales_report_days',
    //   value_type: 'integer',
    // },
    {
      category: 'report',
      key: 'sales_report_recipients',
      value_type: 'array',
      description: 'Sales report recipients',
    },
    {
      category: 'report',
      key: 'sales_report_days',
      value_type: 'integer',
      description: 'Sales report days',
    },
    {
      category: 'report',
      key: 'voucher_report_recipients',
      value_type: 'array',
      description: 'Voucher report recipients',
    },
    {
      category: 'report',
      key: 'voucher_report_days',
      value_type: 'integer',
      description: 'Voucher report days',
    },
    {
      category: 'report',
      key: 'service_report_recipients',
      value_type: 'array',
      description: 'Service report recipients',
    },
    {
      category: 'report',
      key: 'service_report_days',
      value_type: 'integer',
      description: 'Service report days',
    },
    {
      category: 'report',
      key: 'tangible_report_recipients',
      value_type: 'array',
      description: 'Tangible report recipients',
    },
    {
      category: 'report',
      key: 'tangible_report_days',
      value_type: 'integer',
      description: 'Tangible report days',
    },
    {
      category: 'report',
      key: 'quota_application_report_recipients',
      value_type: 'array',
      description: 'Quota application report recipients',
    },
    {
      category: 'report',
      key: 'quota_application_report_days',
      value_type: 'integer',
      description: 'Quota application report days',
    },
  ]

  return {
    categories,
    valueTypes,
    settingKeys,
  }
}
