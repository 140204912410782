<template>
  <div class="pa-5">
    <v-form ref="formElem">
      <v-select
        v-model="form.category"
        :items="categoryOptions"
        item-text="title"
        item-value="value"
        label="Category"
        required
        :disabled="mode == 'edit'"
      ></v-select>

      <v-select
        v-model="form.key"
        :items="keysOptions"
        item-text="title"
        item-value="value"
        label="Key"
        required
        :disabled="mode == 'edit'"
      ></v-select>

      <v-select
        v-if="form.category === 'notification' && form.key === 'locale'"
        v-model="form.value"
        :items="locales"
        item-text="title"
        item-value="value"
        label="Value"
        required
      ></v-select>

      <v-combobox
        v-else-if="valueType === 'array'"
        v-model="form.value"
        label="Value"
        multiple
        required
        hint="Press tab to add a new tag"
        persistent-hint
      >
        <template #selection="{ item }">
          <v-chip class="mt-1">
            {{ item }}
          </v-chip>
        </template>
      </v-combobox>

      <v-text-field
        v-else="valueType === 'string'"
        v-model="form.value"
        label="Value"
        required
      ></v-text-field>

      <v-btn
        color="primary"
        :loading="loading"
        class="text-center mt-5"
        @click="validate"
      >
        Submit
      </v-btn>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-form>
  </div>
</template>

<script>
import { required } from '@core/utils/validation';
import { } from '@mdi/js';
import { computed, ref, watch } from '@vue/composition-api';
import useMerchantSetting from '../useMerchantSetting';

export default {
  name: 'SettingForm',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const mode = ref(props.resource ? 'edit' : 'create')

    const { categories, settingKeys } = useMerchantSetting()

    const categoryOptions = computed(() => categories.map(category => ({
      title: category[0].toUpperCase() + category.slice(1),
      value: category,
    })))

    const keysOptions = computed(() => settingKeys
      .filter(settingKey => settingKey.category === form.value.category)
      .map(settingKey => ({
        title: settingKey.description,
        value: settingKey.key,
      })))

    const valueType = computed(() => settingKeys.find(settingKey => settingKey.key === form.value.key)?.value_type)

    const locales = ref([
      {
        title: 'English',
        value: 'en',
      },
      {
        title: '繁體中文',
        value: 'tc',
      },
      {
        title: '简体中文',
        value: 'sc',
      },
    ])

    const getInitialData = () => ({
      category: props.resource?.category ?? 'notification',
      key: props.resource?.key,

      // email: props.resource?.value ?? null,
      // locale: props.resource ? (props.resource.key == 'notification_locale' ? props.resource?.value : 'en') : 'en',
      value_type: props.resource?.value_type ?? 'string',
      value: props.resource?.value,
    })

    const form = ref({ ...getInitialData() })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) {
        form.value.value_type = valueType.value

        emit('submit', form.value)
      }
    }

    watch(
      () => form.value.key,
      () => {
        form.value.value = null
      },
    )

    return {
      mode,

      categoryOptions,
      keysOptions,
      valueType,

      locales,

      form,
      formElem,

      validate,
      required,
    }
  },
}
</script>
