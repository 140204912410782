import axios from '@axios'

const getSettings = async merchantId => {
  const endPoint = `/admin/merchants/${merchantId}/settings`

  const { status, data } = await axios.get(endPoint)

  if (status == 200) {
    return data.data
  }

  return null
}

const storeSettings = async (merchantId, payload) => {
  const endPoint = `/admin/merchants/${merchantId}/settings`

  const { status, data } = await axios.post(endPoint, payload)

  if (status == 201) {
    return data.data.records
  }

  return null
}

const updateSetting = async (merchantId, settingId, payload) => {
  const endPoint = `/admin/merchants/${merchantId}/settings/${settingId}`

  const { status, data } = await axios.patch(endPoint, payload)

  if (status == 200) {
    return data.data
  }

  return null
}

const deleteSetting = async (merchantId, settingId) => {
  const endPoint = `/admin/merchants/${merchantId}/settings/${settingId}`

  const { status, data } = await axios.delete(endPoint)

  if (status == 200) {
    return data.data
  }

  return null
}

export { getSettings, storeSettings, updateSetting, deleteSetting }
